
import detectForm from './detectform';
import isExisted from './isexisted.js';
import preLoad from './preload' ; 

export  {
  detectForm,
  isExisted,
  preLoad
}
