
import React, { Component } from 'react';


const Header = (props)=>{

  return(
    <div style={{
      backgroundColor:'#20BD8D',
      height:'50px', lineHeight:'50px',
      borderBottom:'1px solid rgba(0,0,0,0.1)',
      color:'#fff', fontFamily:'Roboto', fontSize:'18px',
      fontWeight:400,
      paddingLeft:20
    }}>

        Demo TicketBox

    </div>
  )
}
export default Header ;
